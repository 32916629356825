<template>
    <div class="old-member-popup popup">
        <div class="mask" />
        <div class="panel">
            <v-icon
                class="close-icon"
                @click="close"
            >
                mdi-close
            </v-icon>
            <div class="text">
                <div class="content">
                    {{ $t('OldMemberPopup.content') }}
                </div>
                <div class="checkbox-box">
                    <v-checkbox
                        v-model="notShow"
                        :label="$t('OldMemberPopup.label')"
                        color="#F97E2B"
                        class="checkbox"
                    />
                </div>
                <div class="actions">
                    <div
                        class="btn bg-gray confirm"
                        @click="confirm"
                    >
                        {{ $t('OldMemberPopup.button[0]') }}
                    </div>
                    <div
                        class="btn bg-orange forget"
                        @click="goto({ name: 'ForgetPassword' })"
                    >
                        {{ $t('OldMemberPopup.button[1]') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            notShow: false,
        };
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {
        close() {
            console.log('close');
            this.$emit('close');
        },
        confirm() {
            if (this.notShow) {
                localStorage.setItem('notShowOldMemberPopup', true);
            }
            this.$emit('close');
        },
        goto(route) {
            if (this.notShow) {
                localStorage.setItem('notShowOldMemberPopup', true);
            }
            this.$router.push(route);
        },
    },
};
</script>

<style lang="scss" scoped>
.old-member-popup {
    .panel {
        padding: 80px 10px 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .text{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            height: 50vh;
            overflow: scroll;
            .checkbox-box{
                width: 100%;
            }
            .content {
                max-width: 600px;
                font-weight: 700;
                font-size: 28px;
                line-height: 2;
                text-align: center;
                letter-spacing: 0.1em;
                color: $color-gray;
                white-space: pre-line;
            }
        }
    }
}
@media screen and (max-width: 1020px) {
    .old-member-popup {
        .panel {
            padding: 100px 80px 60px 80px;
            .text{
                .content {
                    font-size: 21px;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .old-member-popup {
        .panel {
            padding: 80px 20px 40px 20px;
            .text{
                .content {
                    font-size: 18px;
                }
            }
        }
    }
}
</style>

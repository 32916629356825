<template>
    <div class="login page-center">
        <div class="web-content">
            <div class="process-structure">
                <div class="auth-process-body">
                    <div class="process-page-title-row">
                        <div class="process-page-title">
                            {{ $t('auth.login.title') }}
                        </div>
                    </div>
                    <div class="auth-form-block">
                        <div
                            class="google-login"
                            @click="googleLogin"
                        >
                            <img
                                src="@/assets/register/google-icon.png"
                                alt=""
                            >
                            <div class="text">
                                Log in with Google
                            </div>
                        </div>
                        <div class="tips">
                            {{ $t('auth.login.tips') }}
                            <a
                                href="/policy-terms"
                                target="_blank"
                            >{{ $t('auth.login.terms') }}</a>
                        </div>
                        <div class="divided">
                            <div class="line" />
                            <span>{{ $t('auth.login.or') }}</span>
                            <div class="line" />
                        </div>
                        <div class="login-form">
                            <v-form
                                ref="form"
                                v-model="valid"
                            >
                                <v-text-field
                                    v-model="loginForm.account"
                                    outlined
                                    :label="$t('auth.login.label[0]')"
                                    placeholder=""
                                    hint=""
                                    :rules="emptyRule"
                                    persistent-hint
                                    class="border-orange-input"
                                    @keypress.enter="login"
                                />
                                <v-text-field
                                    v-model="loginForm.password"
                                    :type="showPWD ? 'text' : 'password'"
                                    :append-icon="
                                        showPWD ? 'mdi-eye' : 'mdi-eye-off'
                                    "
                                    outlined
                                    :label="$t('auth.login.label[1]')"
                                    placeholder=""
                                    hint=""
                                    persistent-hint
                                    :rules="passwordRule"
                                    class="border-orange-input"
                                    @keypress.enter="login"
                                    @click:append="showPWD = !showPWD"
                                />
                            </v-form>
                            <router-link
                                class="forget-password-link"
                                :to="{ name: 'ForgetPassword' }"
                            >
                                {{ $t('auth.login.forget') }}
                            </router-link>
                            <button
                                class="submit-btn"
                                @click="login"
                                @keypress.enter="login"
                            >
                                {{ $t('auth.login.login') }}
                            </button>
                            <div class="register-box">
                                {{ $t('auth.login.register.title') }}
                                <router-link
                                    class="register-link"
                                    :to="{ name: 'RegisterMemberData' }"
                                >
                                    {{ $t('auth.login.register.text') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <OldMemberPopup
            v-if="oldMemberPopupShow"
            @close="oldMemberPopupShow = false"
        />
    </div>
</template>

<script>
// import API from '@/api';
import { mapMutations, mapActions } from 'vuex';
import OldMemberPopup from '@/components/auth/OldMemberPopup.vue';
import _ from 'lodash';

export default {
    components: {
        OldMemberPopup,
    },
    data() {
        return {
            oldMemberPopupShow: true,
            valid: false,
            showPWD: false,
            loginForm: {
                account: '',
                password: '',
            },
            passwordRule: [
                (v) => !!v || this.$t('auth.login.rule[0]'),
                (v) => (v && v.length >= 6) || this.$t('auth.login.rule[1]'),
                (v) => !v.match(/\s/) || this.$t('auth.login.rule[2]'),
            ],
            emptyRule: [(v) => !!v || this.$t('auth.login.rule[0]')],
        };
    },
    computed: {},
    created() {
        if (localStorage.getItem('notShowOldMemberPopup')) {
            this.oldMemberPopupShow = false;
        }
    },
    mounted() { },
    methods: {
        ...mapMutations('mLoading', ['setLoading']),
        ...mapActions('mAuth', ['LoginUser']),
        login() {
            this.$refs.form.validate();
            if (this.valid) {
                this.LoginUser(this.loginForm);
            }
        },
        googleLogin() {
            localStorage.setItem('googleLoginLanguage', this.$route.path.split('/')[1]);
            this.startGoogleLogin();
        },
        async startGoogleLogin() {
            console.log('window.location.origin', window.location.origin);
            const params = {
                client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                scope: 'profile email',
                redirect_uri: `${window.location.origin}/google-login`,
                response_type: 'code',
                prompt: 'select_account',
            };
            const queryString = _.map(params, (v, k) => `${k}=${v}`).join('&');
            window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${queryString}`;
        },
    },
};
</script>

<style lang="scss">
.login {
    .auth-form-block {
        margin-top: 65px;

        .tips {
            letter-spacing: 0.1em;
            font-weight: 700;
            line-height: 1.5;
            text-align: center;
            margin-top: 7px;

            a {
                color: $color-orange;
            }
        }
    }
    .google-login {
        border: 1px solid $color-orange;
        border-radius: 5px;
        background-color: white;
        font-weight: 700;
        font-size: 18px;
        line-height: 1;
        letter-spacing: 0.1em;
        color: $color-gray;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75px;
        cursor: pointer;
        padding: 0 26px;

        img {
          width: 30px;
          height: 30px;
          margin-right: auto;
        }

        .text{
          margin-right: auto;
        }
    }

    .divided {
        display: flex;
        color: $color-orange;
        align-items: center;
        margin: 30px 0;
        font-weight: 700;

        .line {
            width: 50%;
            margin: 0 16px;
            height: 1px;
            border-top: 1px solid $color-orange;
        }
    }

    .forget-password-link {
        color: $color-orange;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.1em;
    }

    .register-box {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.1em;
        color: $color-gray;
        text-align: center;
    }

    .register-link {
        color: $color-orange;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.1em;
    }

    .submit-btn {
        margin-top: 60px;
        margin-bottom: 25px;
    }
}
</style>
